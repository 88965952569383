<template>
    <v-row>
        <v-col class="px-5">
            <template>
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="margin-top: 30px; " color="green" dark v-bind="attrs" v-on="on">
                            {{ $t('add driver') }}+
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ $t('add driver') }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field v-model="data.name" type="text" :label="$t('name')"
                                            :hint="$t('requird')" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field type="number" v-model="data.phone" :label="$t('phone number')"
                                            :hint="$t('requird')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field type="number" v-model="data.phone_2"
                                            :label="$t('land phone number')"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6"><v-text-field v-model="data.address" type="text"
                                            :label="$t('address')" required></v-text-field></v-col>
                                    <v-col cols="12" sm="6" md="6"><v-text-field v-model="data.username" type="text"
                                            :label="$t('user name')" :hint="$t('requird')"
                                            required></v-text-field></v-col>
                                    <v-col cols="12" sm="6" md="6"><v-text-field v-model="data.password" type="text"
                                            :label="$t('password')" required
                                            :hint="$t('password should be at least 8 char')"></v-text-field></v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog = false">
                                {{ $t('cancel') }}
                            </v-btn>
                            <v-btn :color="btnColor" text @click="setData">
                                <span v-if="!driver.driverState.loading"> {{ $t('storage') }}</span>
                                <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-col>
    </v-row>
</template>
<script>
import { driver } from '../../../store/drivers'
import notify from '../../../helpers/toast-notifications'

export default {
    data: () => ({
        driver,
        dialog: driver.driverState.loading,
        menu: false,
        data: {
            name: null,
            phone: null,
            phone_2: null,
            address: null,
            username: null,
            password: ''

        },

    }),
    methods: {
        setData() {
            if (this.btnColor == 'blue') {
                let data = this.data
                driver.storeDriver(data)
                this.data.name = null
                this.data.phone = null
                this.data.phone_2 = null
                this.data.address = null
                this.data.username = null
                this.data.password = ''

            } else {
                notify(
                    this.$t('please complete all info'),
                    { timeout: 4000, rtl: true },
                    "error"
                );
            }
        },

    },
    computed: {
        btnColor() {
            if (this.data.name == null | this.data.username == null | this.data.phone == null | this.data.password.length < 8) {
                return 'gray'
            } else {
                return 'blue'
            }
        }
    },
}
</script>