<template>
    <v-container>
        <v-row>
            <create-driver></create-driver>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0" class="pt-5">
                    <base-table-3 :tableOptions="tableOptions" @re-fetch-data="fetchData"
                        @re-fetch-paginated-data="fetchData($event)">
                        <v-icon color="red">mdi-delete</v-icon>
                    </base-table-3>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import createDriver from './createDriver.vue'
import { driver } from '../../../store/drivers'
import basetable3 from '../../../components/base/baseTable3.vue'
export default {
    components: {
        createDriver,
        'base-table-3': basetable3,
    },
    data() {
        return {
            driver,
        }
    },
    methods: {
        fetchData(page) {
            driver.getDrivers(page)

        },
    },
    computed: {
        tableOptions() {
            return driver.driversTable

        }
    },
    mounted() {
        driver.getDrivers(1)
    }

}
</script>
